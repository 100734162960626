<template>
  <div class="content-box-padding">
    <div class="line-text" style="margin-top: 20px">
      综合叠加生态、水、大气和土壤等要素管控分区和行政区域、工业园区、城镇规划边界等；<br />
      统筹划定优先、重点和一般三类环管控单元，重点地区空间管控精度达到乡镇及园区级别；<br />
      针对管控单元，总体采用结构化的清单模式，从省域、区域、市域不同层级，对环境管控单元提出具体生态环境准入要求。<br />
    </div>
    <img
      src="~assets\image\business\三线一单编制.png"
      alt="三线一单编制"
      style="margin: 50px auto 0px auto; width: 80%"
    />
  </div>
</template>
